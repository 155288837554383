import request from "@/api/config.js";

export function sendMessage(data) {
  return request({
    timeout: 300000,
    url: `career_guidance/chat/message`,
    method: "post",
    data,
  });
}

export function createOrGetAttempt(user_id) {
  return request({
    url: `career_guidance/chat/createOrGetAttempt/${user_id}`,
    method: "get",
  });
}

export function userHasAttempts(user_id) {
  return request({
    url: `career_guidance/chat/userHasAttempts/${user_id}`,
    method: "get",
  });
}

export function setFeedbackForResult(data) {
  return request({
    url: `career_guidance/feedback-for-result`,
    method: "post",
    data,
  });
}

export function setAttemptResultDirections(data) {
  return request({
    url: `career_guidance/set-attempt-result-directions`,
    method: "post",
    data,
  });
}
